export const TemplateList = [
  {
    _id: "2024-02-25-2211",
    title: "Freelance OS",
    slug: "feelance-os",
    type: "free",
    name: "",
    author: "Easlo",
    tagline: "Manage your entire freelance process in Notion.",
    thumbnail: "/images/templates/freelance-os.jpg",
    url: "https://gumroad.com/a/574651347/gpckg",
    isNew: true,
  },
  {
    _id: "2024-02-25-2216",
    title: "Finance Tracker",
    slug: "finance-tracker",
    type: "free",
    name: "",
    author: "Easlo",
    tagline: "The ultimate Notion dashboard to track all your finances.",
    thumbnail: "/images/templates/finance-tracker-easlo.png",
    url: "https://gumroad.com/a/574651347/beygm",
    isNew: true,
  },
  {
    _id: "2024-02-25-2229",
    title: "Travel Planner",
    slug: "travel-planner",
    type: "free",
    name: "",
    author: "Easlo",
    tagline: "Plan your next travel better with Notion",
    thumbnail: "/images/templates/travel-planner.jpg",
    url: "https://gumroad.com/a/574651347/nqtvy",
    isNew: true,
  },
  {
    _id: "2024-02-25-2100",
    title: "Notion Ultimate Life Planner",
    slug: "ultimate-life-planner",
    type: "free",
    name: "",
    author: "Notionway",
    tagline: "Plan and achieve your goals effortlessly with Notion Ultimate Life Planner.",
    thumbnail: "/images/templates/ultimate-life-planner.jpg",
    url: "https://gumroad.com/a/608336851/qidem",
  },
  {
    _id: "2024-02-25-2200",
    title: "Notion Small Business Kit",
    slug: "small-business-kit",
    type: "free",
    name: "",
    author: "Notionway",
    tagline:
      "The easiest way to start and manage your small business. In this Kit you will find everything you need to know and have as a successful small business owner.",
    thumbnail: "/images/templates/small-business-kit.jpg",
    url: "https://gumroad.com/a/608336851/swFeYK",
  },
  {
    _id: "2024-02-25-2207",
    title: "Notion Ultimate Finance Tracker",
    slug: "ultimate-finance-tracker",
    type: "free",
    name: "",
    author: "Notionway",
    tagline:
      "Notion Ultimate Finance Tracker Kit will help you make smarter financial decisions and stay in control of your finances.",
    thumbnail: "/images/templates/ultimate-finance-tracker.jpg",
    url: "https://gumroad.com/a/608336851/ebkfb",
  },
];
