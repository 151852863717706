import Link from "next/link";
import Image from "next/image";

export default function CallToActionIdeaCard() {
  return (
    <section id="call-to-action-section" className="py-5">
      <div className="py-5 bg-light rounded-3 border">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h3 className="mb-3">
                Didn't you find the right widget? Tell us what you are looking
                for!
              </h3>
              <h6 className="subtitle font-weight-normal">
                We are always interested in expanding our widgets. We are
                grateful for every idea and we will inform you about it as soon
                as we publish the widget.
              </h6>
              <a href="https://tally.so#tally-open=mZ826V&tally-layout=modal&tally-width=600&tally-overlay=1&tally-emoji-text=👋&tally-emoji-animation=wave">
                <button className="btn btn-primary btn-md mt-3">
                  Send Us Your Idea
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
