export const featureToggles = {
  betaTesters: [
    "vincent@manometric.nl",
    "koak@koak.net",
    "daniel.santos.business@gmail.com",
    "carlos@teknacreative.com",
    "ciao@chiarabataglioli.it",
    "yufei.xiao@uni.minerva.edu",
    "munchkinukulele@gmail.com",
    "angeline.blb06@outlook.com",
    "susanne@travelyourlife.de",
    "hello@bytesandpieces.nl",
    "viktoria@clange.de",
    "viviane.soares@ufam.edu.br",
    "s1901459@ed.ac.uk",
    "britiny@stanford.edu",
    "melody.pang10@rafflesgirlssch.edu.sg",
    "joyeeta.chatterjee@mail.mcgill.ca",
    "shasmeen.karani.732@k12.friscoisd.org",
    "jordan.nicole.conley@gmail.com",
    "ca.tyandrade2009@hotmail.com",
    "18aruqayyah@hwga.org.uk",
    "catherineyerolemou@gmail.com",
    "fiona.mari@icloud.com",
    "paulina.naetscher@fau.de",
    "zhihesince1991@gmail.com",
  ],
};
