import Link from "next/link";
import Image from "next/image";
import { TemplateList } from "../../helpers/template-list";

export default function TemplateWidget() {
  const templates = TemplateList;

  return (
    <section id="widgets-section" className="py-5">
      <h2 className="mb-4 h4">Templates</h2>
      <div className="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-2">
        {templates.map((widget) => {
          const link = widget.isComing ? "#/" : `${widget.url}`;
          return (
            <div className="px-4 my-4" key={widget._id}>
              <Link
                href={link}
                className="text-decoration-none text-reset c-p position-relative"
                key={widget._id}
                target="_blank"
              >
                <div
                  className={`rounded-3 ${
                    widget.isComing
                      ? "border tw-h-full tw-bg-[#f6f9fc] tw-cursor-auto"
                      : "border hover:tw-shadow-lg"
                  }`}
                  key={widget._id}
                >
                  <div style={{ height: "200px" }}>
                    <img
                      src={widget.thumbnail}
                      className="tw-object-cover tw-w-full tw-h-[200px] tw-rounded-t-md"
                      alt={widget.title}
                    />
                  </div>
                  <div className="p-3 card-desc bg-grey">
                    <h5>
                      {widget.title}
                      {widget.isNew && (
                        <span
                          className="mx-1 mt-1 text-white align-top badge bg-primary rounded-pill"
                          style={{ fontSize: "10px" }}
                          title={"New Widget"}
                        >
                          New
                        </span>
                      )}
                      {widget.isComing && (
                        <span
                          className="mx-1 mt-1 text-white align-top badge bg-primary rounded-pill"
                          style={{ fontSize: "10px" }}
                          title={"New Widget"}
                        >
                          Release on Saturday
                        </span>
                      )}
                    </h5>
                    <p className="tw-min-h-[80px] tw-my-auto tw-text-sm">{widget.tagline}</p>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
}
